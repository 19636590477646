//Header//

.hero-and-section {
  display: grid;
  grid-template-rows: [gradient-top] auto [picture-top] 100px [gradient-bottom] auto [picture-bottom] auto [content-bottom];
  grid-template-columns: [case-start] 1fr [content-start] 60vw [content-end] 1fr [case-end];

  @media (max-width: 1100px) {
    grid-template-columns: [case-start] 1fr [content-start] 80vw [content-end] 1fr [case-end];
  }
}
  
.hero-and-section__title {
  grid-row: gradient-top / picture-top;
  grid-column: content-start / content-end;
  margin:150px 0 100px 0;

  display: grid;
  place-items: center;

  grid-template-columns: [bumper-start] 1fr [inner-content-start] 90% [inner-content-end] 1fr [bumper-end];
  
  @media (max-width: 1100px) {
    grid-template-columns: [bumper-start] 1fr [inner-content-start] 100% [inner-content-end] 1fr [bumper-end];
  }
}

.hero-and-section__title h1,
.hero-and-section__title p {
  grid-column: inner-content-start / inner-content-end;
}

.hero-title {
  font-size: 4em;
  font-weight: 700;
  font-family: $headerFont;
  margin-bottom: 20;
  line-height: 1.2;
  max-width: 25ch;

  @media (max-width: 800px) {
    font-size: 3em;
  }
}

#hero-subtitle {
  position:relative;
  visibility: hidden;
}

.case-section-hero {
  grid-column: content-start/content-end;
  grid-row: picture-top / picture-bottom;
  margin: 0 auto;
  width: 100%;
  
  @media (min-width: 2100px) {
    width: 1260px;
  }

  // @media (max-width: 900px) {
  //   grid-row: header-middle-1/header-middle-2;
  // }
}

.gradient-bg {
  grid-column: case-start / case-end;
  grid-row: gradient-top / gradient-bottom; 
  background: linear-gradient(90deg, #FFEEEE 0%, #DDEFBB 100%);
}
.gradient-bg-purple {
  grid-column: case-start / case-end;
  grid-row: gradient-top / gradient-bottom; 
  background: linear-gradient(90deg, #DDD6F3 0%, #FAACA8 100%);
}
.gradient-bg-blue {
  grid-column: case-start / case-end;
  grid-row: gradient-top / gradient-bottom; 
  background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
}


// .case-header {
//   display: grid;
//   grid-template-columns: [case-start] 1fr [content-start] 60vw [content-end] 1fr [case-end];
//   // grid-template-rows: [header-top] auto [header-middle-1] 40vh [header-middle-2] auto [header-bottom] auto;
//   background: linear-gradient(90deg, #FFEEEE 0%, #DDEFBB 100%);
  
// }

// .case-header__body {
//   grid-column: content-start/content-end;
//   padding: 50px;
//   display: grid;
//   justify-content: center;
//   align-content: center;
//   margin-bottom: 150px;
// }


.case-section-header_description {
  font-size: 1.8em;
  max-width: 45ch;
}

//Case Content//

.content-wrapper {
  display: grid;
  grid-template-columns: [page-start] 1fr [content-start] 60vw [content-end] 1fr [page-end];
  background-color: $backgroundDefault;
  padding: 1em 0;

  @media (max-width: 750px) {
    display: grid;
    grid-template-columns: 1fr [content-start] 80vw [content-end] 1fr;
  }
}


.bottom-spacing {
  padding-bottom:5em;
}

.column-spacing {
  padding-top:2em;
}

.content-wrapper-wide {
  padding: 10em 0;
  width: 100vw;
  background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
  display: flex;
  justify-content: space-evenly;

  @media (max-width: 1580px) {
    flex-direction: column;
  }
  @media (max-width: 900px) {
    padding: 5em 0;
  }
}

.content-wrapper-wide--gradient-purple {
  background-image: linear-gradient(to top, #fdcbf1 0%, #fdcbf1 1%, #e6dee9 100%);

  @media (max-width: 1580px) {
    flex-direction: column;
  }
  @media (max-width: 900px) {
    padding: 5em 0;
  }
}

.content-wrapper-wide_image--space {
  width: 30%;
  

  @media (max-width: 1580px) {
    width: 100vw;
    padding: 0% 5%;
    text-align: center;
    margin-bottom: 1em;
  }

  img {
    width: 100%;

    @media (max-width: 1580px) {
      width: 90%;
    }
  }

  p {
    font-size: 1.1em;
    color: $bodyLight;
    padding-top: 1em;
    text-align: center;
    max-width:none;
  }

  @media (max-width: 900px) {
    font-size:1em;
  }
}

.content-wrapper-wide_image--two-images {
  width: 45%;

  @media (max-width: 1580px) {
    width: 100vw;
    padding: 0% 5%;
    text-align: center;
  }
}

// .content-wrapper-wide {
//   display: grid;
//   justify-content: center;
//   align-items: center;
  

//   &--background-gradient {
//     background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
//     margin-bottom: 1.5em;
//   }
// }

// .content-wrapper-wide_image {
//   grid-column: content-start/banner-end;
//   display: flex;
//   justify-content: space-between;
//   padding: 8em;
// }

// .content-wrapper-wide_image--space {
//   margin-right: 1em;
// }

.case-section-content_background {
  grid-column: content-start / content-end;
  padding: 2em 0;
}

.case-section-content_background--description {
font-family: $subheaderFont;
font-size: 1.8em;
text-transform: none;
color: $bodyColor;
}

.content-wrapper-section {  
  grid-column: content-start / content-end;
  background-color: $backgroundDefault;
  display: grid;
  justify-content: center;
}


.content-wrapper-section_image {
  width: 90%;
  padding: 20px 0;
  align-content: center;
  display: block;
}

.content-wrapper-section_image--description {
  font-size: 1.1em;
  color: #333333;
  padding: 1em 0;
  font-style: italic;
  text-align: center;
}


.content-wrapper-section--center {
  display: grid;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.content-wrapper-section_list {
  margin-left: 1.5em;

  
}

.content-wrapper-section_card-list li {
  list-style: none;
  position:relative;
  background-color: #E2FFE7;
  padding: 1em;
  border-radius: 12px;
}
.content-wrapper-section_card-list--purple li {
  list-style: none;
  position:relative;
  background-color: #F0E6F2;
  padding: 1em;
  border-radius: 12px;
}

.content-wrapper-section_callout {
  background-color: #E2FFE7;
  padding: 1em;
  border-radius: 12px;

}

.content-wrapper-section_quote--card {
  box-shadow: 14px 14px 0 -4px gold, 14px 14px 0 0;
  width: 600px;
  height: 150px;
  padding: 1em;
  margin-bottom: 1em;

}

blockquote {
  box-shadow: 10px 5px 5px #D9E7FD;
  padding: 1.5em;
  font-style: italic;
  margin-bottom: 1.5em;
}

// highlight effect //

span.highlight {
background: linear-gradient(104deg, rgba(130, 255, 173,0) 0.9%, rgba(130, 255, 173,1.25) 2.4%, rgba(130, 255, 173,0.5) 5.8%, rgba(130, 255, 173,0.1) 93%, rgba(130, 255, 173,0.7) 96%, rgba(130, 255, 1732,0) 98%), linear-gradient(183deg, rgba(130, 255, 173,0) 0%, rgba(130, 255, 173,0.3) 7.9%, rgba(130, 255, 173,0) 15%);
padding: 0.6em 10px;
margin: 0;
border-radius: 5.5px;
text-shadow: -12px 12px 9.8px rgba(130, 255, 173,0.7), 21px -18.1px 7.3px rgba(255, 255, 255,1), -18.1px -27.3px 30px rgba(255, 255, 255,1);
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

//////////////////////

// Old //

// .project-wrapper {
//   display: grid;
//   grid-template-columns: [page-start] 1fr [content-start] 40vw [center] 40vw [content-end] 1fr [page-end];
//   // grid-template-rows: [header] auto [header-end] auto [content-end];
// }

 h1 {
  max-width: 25ch;
 }

h2 {
  font-size: 2.2rem;
  padding-bottom: 1em;
  max-width: 25ch;

}

h3 {
  font-size: 1.5rem;
  padding-bottom: 1em;
  line-height: 1.2em;
  max-width: 45ch;
}

h4 {
  color: #9D51FC;
  font-family: $bodyFont;
  font-weight: 400;
  font-size: 1.6em;
  margin: 0.7em 0;
  max-width: 45ch;
}


a {
  text-decoration: none;

  &.highlight {
    text-decoration: none;
    border-bottom: 4px solid $fontSecondary;
    box-shadow: inset 0 -8px 0 $fontSecondary;
    color: inherit;
    transition: background 0.1s cubic-bezier(.33, .66, .66, 1);
  }

  &.highlight:hover {
    background: $fontSecondary;
  }
}

p {
  max-width: 60ch;
}

ul {
  list-style-type: disc;
  list-style-position: outside;

  li {
    margin-bottom: 0.5em;
    line-height: 1.6;
    max-width: 65ch;
  }
}
// All Case Studies

.case-study-section {
  padding: 5em 0;
  background-color: #fafafa;
  grid-column: page-start / page-end;
  grid-row: auto;
  display: flex;
  flex-direction: column;

  // Nested grid
  display: grid;
  grid-template-columns: [page-start] 1fr [content-start] 80vw [content-end] 1fr [page-end];

  // Background Colors

  &--background-white {
    background-color: $backgroundDefault;
  }

  &--accent-background {
    background-color: $lanternResults;
  }
  &--background-gray {
    background-color: $backgroundCasestudy;
  }

  &--lantern-results {
    background-color: $lanternResults;
  }
  &--lantern-hero {
    background-color: #ede7ff;
  }
  &--open-hero {
    background-color: $openDark;
  }
  &--open-results {
    background-color: $openLight;
  }
  &--streamloan-results {
    background-color: $streamloanLight;
  }
  &--background-default {
    background-color: $backgroundDefault;
  }

  // Text Emphasis

  .case-study-section--emphasis {
    font-weight: bold;
  }

  // Header Section

  .case-study-section__tags-container {
    display: flex;
    align-items: center;

    .case-study-section__tag {
      font-family: $headerFont;
      text-transform: uppercase;
      text-align: center;
      margin: 0 0.5em 1.125rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5em 0.9em;
      font-size: 0.9rem;
      border: 3px solid $ctaColor;
      color: $ctaColor;
      border-radius: 40px;
    }
  }

  .case-study-section__description span {
    display: block;
  }

  .case-study-section__subtitle {
    font-weight: 600;
    padding-top: 0.8em;
    margin-bottom: 0.7em;
  }

  .case-study-section__subtitle--card {
    text-transform: capitalize;
    font-weight: 600;
    padding-top: 0.8em;
    margin-bottom: 0.7em;
    font-size: 1.125em;
  }

  .case-study-section__duration {
    display: block;
    padding: 1em 0 1em 0;
    border: 0;
    border-top: 2px solid $bodyLight;
    margin: 1em 0 1em 0;
  }

  .case-study-section__quote {
    display: block;
    font-weight: bold;
    padding-bottom: 10px;
  }

  .case-study-section__business {
    ul {
      list-style-type: circle;
      list-style-position: inside;
      margin-left: 1.1em;

      li {
        margin-bottom: 0.5em;
        line-height: 1.6;
        text-indent: -1.1em;
      }
    }
  }

  // Case Study Rows

  &--accent-background {
    background-color: $lanternLight;
  }

  &--solution {
    padding: 5em 0 0 0;
  }

  //  Final results section

  .case-study-section__image--final-results {
    width: 100%;
  }
  .case-study-section__image--final-results-streamloan {
    max-height: 800px;
    max-width: 100%;
  }
  .case-study-section__image--row-reverse {
    width: 100%;
  }
  .case-study-section__image--row-reverse-streamloan {
    max-width: 100%;
    margin-right: 10%;
  }
}

.case-study-section__image--hero {
  max-width: 75%;
  width: 50%;
}
.case-study-section__image--hero-streamloan {
  max-width: 100%;
  margin-bottom: 1em;
}

.case-study-section__content {
  &--row {
    margin-top: 2.5em;
  }
}

.case-study-section__list {
  ul {
    list-style-type: circle;
    list-style-position: outside;
    margin-left: 0.8em;

    li {
      margin-bottom: 0.5em;
      line-height: 1.6;
      // text-indent: 2em;
    }
  }
}

.case-study-section__btn {
  align-self: center;
  font-family: $headerFont;
  background-color: $ctaColor;
  color: $backgroundDefault;
  padding: 1em 2em;
  border: 4px solid $ctaColor;
  margin-top: 2em;
  text-align: center;
  font-size: 1em;

  @media (min-width: 900px) {
    font-size: 1.3em;
  }

  @media (min-width: 1160px) {
    font-size: 1.4em;
  }

  &:hover {
    background-color: $backgroundDefault;
    color: $ctaColor;
  }
}

.case-study-section__content {
  display: flex;
  flex-direction: column;
  grid-column: content-start / content-end;

  // &--narrow {
  //   & > * {
  //     width: 84%;
  //     align-self: center;
  //   }
  // }
  &--row-open {
    display: flex;
    flex-direction: column;
    justify-content: center;

    & img {
      max-width: 100%;
      margin-bottom: 6em;
    }

    .case-study-section__subheader {
      text-align: center;
    }
    .case-study-section__cta {
      text-align: center;
      & a {
        color: $ctaColor;
        text-decoration: underline;

        &:hover {
          color: $accentColor;
        }
      }
    }
  }
}

.case-study-section__image {
  max-width: 100%;
  height: auto;
  grid-column: content-start / content-end;

  &--image-shadow {
    box-shadow: 0px 4px 30px $imageShadow;
  }
}

.case-study-section__image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2.5em;
  & p {
    // text-align: center;
    margin-bottom: 2em;
    font-weight: bold;
    padding-top: 2em;
  }

  & div {
    margin-left: 3em;
  }
}
.case-study-section__image--row {
  max-width: 100%;
  margin-bottom: 2em;
}

.finalresults-container {
  background-color: $lanternResults;
  padding-top: 2em;
  grid-column: content-start / content-end;
  grid-row: auto;

  .finalresults-container__info {
    padding-bottom: 2em;
  }
  .finalresults-container__image {
    max-width: 100%;
    margin-bottom: 2em;
  }
}

// Research Section

.case-study-section__card-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  p {
    font-size: 1em;
  }

  @media (min-width: 950px) {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .case-study-section__subtitle {
    // color: $accentColor;
    font-family: $bodyFont;
  }

  .case-study-section__icon--streamloan {
    color: $streamloanAccent;
  }
  .case-study-section__icon--lantern {
    color: $accentColor;
  }

  .fa,
  .fas,
  .far {
    font-size: 1.5em;
  }

  .case-study-section__card-item {
    flex: 1;
    margin-bottom: 2em;
    padding: 1.5em;
    border-radius: 1em;

    @media (min-width: 950px) {
      // padding: 1em;
      margin: 1.5em 1em;
    }
  }
}

// Iteration Section

.case-study-section__iteration-container {
  .case-study-section__image {
    margin: 2em 0;
  }

  .case-study-section__iteration-item {
    margin: 3em 0 3em 0;
    display: flex;
    flex-wrap: wrap;
  }
  .case-study-section__image-container {
    display: flex;
    justify-content: center;
  }
}

.case-study-section__image--lantern-iteration {
  width: 70%;
  max-width: 70%;
  align-self: center;
}

.case-study-section__numbered-container {
  // display: flex;
  // flex-direction: column;
  // justify-content: space-around;
  counter-reset: li;
  margin-left: 2em;

  li {
    margin: 0 0 1.5em 3em;
    position: relative;
    &::before {
      counter-increment: li;
      content: counter(li, decimal-leading-zero);
      color: $streamloanAccent;
      margin-right: 0.25em;
      font-family: $headerFont;
      font-size: 2em;
      position: absolute;
      left: -1.5em;
      top: -0.1em;
    }
  }
}

.case-study-section__numbered-header {
  display: block;
  margin: 0.5em 0;
  font-weight: bold;
}

// Streamloan
.case-study-section {
  &--streamloanio {
    padding-bottom: 0;
    background-color: $streamloanDark;
  }
}

.case-study-section__info {
  h1 {
    font-size: 3em;
  }
}

.case-study-section_streamloan-iteration {
  display: flex;
  justify-content: space-between;
  margin-top: 4em;
  flex-direction: column;

  .case-study-section__streamloan-iteration-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &--subtitle {
    // align-self: center;
    text-align: center;
    font-weight: 400;
    color: $subheaderColor;
    font-size: 1em;
  }

  &--image {
    width: 85%;
    align-self: center;
    // margin-left: 7.5%;
    max-width: 100%;
  }
}

.case-study-section__finalscreen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2em 0;
  margin-bottom: 2em;

  h3 {
    padding-bottom: 1.5em;
  }
}

.case-study-section__image--finalscreen {
  max-width: 100%;
  margin-bottom: 1em;
}

// Media queries start here

@media (min-width: 500px) {
  .case-study-section__image--hero-streamloan {
    max-width: 75%;
    width: 75%;
  }

  .case-study-section__image--row-reverse-streamloan {
    max-width: 40%;
    width: 40%;
  }

  .case-study-section__image--finalscreen {
    max-width: 80%;
    margin-bottom: 1em;
  }

  // .case-study-section__list {
  //   ul {
  //     margin-left: 0.9em;

  //     li {
  //       text-indent: 2em;
  //     }
  //   }
  // }

  .case-study-section__info {
    h1 {
      font-size: 3.625em;
    }
  }

  .case-study-section__image--portrait {
    max-width: 35%;
    height: auto;
  }

  .case-study-section__image--final-results-streamloan {
    max-width: 40%;
    height: auto;
  }
}

@media (min-width: 600px) {
  .case-study-section_streamloan-iteration {
    flex-direction: row;
  }

  .case-study-section__image--finalscreen {
    max-width: 50%;
    margin-bottom: 1em;
  }
}

@media (max-width: 899px) {
  p {
    font-size: 1.5em;
  }
}

@media (min-width: 900px) {
  h2 {
    font-size: 3em;
    line-height: 1.25;
  }

  h3 {
    font-size: 1.75em;
  }

  h4 {
    font-size: 1.5em;
    line-height: 1.45;
  }

  p {
    font-size: 1.35em;
  }

  li {
    font-size: 1.25em;
  }

  .case-study-section__content--narrow {
    & > * {
      width: 84%;
      align-self: center;
    }
  }

  .case-study-section__header--lantern {
    width: 100%;
    margin-left: 10%;
    margin-right: 5%;
  }
  .case-study-section__image--final-results {
    max-width: 50%;
  }

  .case-study-section__description--hero {
    font-size: 1.2em;
  }

  .case-study-section__image--row-reverse {
    margin-right: 10%;
    max-width: 50%;
  }

  .case-study-section__image--row-reverse-streamloan {
    // margin-left: 5%;
    max-width: 40%;
    width: 30%;
  }

  .case-study-section__image--portrait {
    max-width: 30%;
    height: auto;
    margin-right: 20%;
  }
  .case-study-section__image--portrait--solution {
    max-width: 30%;
    height: auto;
  }

  .case-study-section__content--narrow > div:first-child {
    margin-right: 10%;
  }
  .case-study-section__content--narrow-streamloan {
    margin-left: 10%;
  }

  .case-study-section__finalscreen {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 2em 0;
    margin-bottom: 2em;

    h3 {
      padding-bottom: 1.5em;
    }
  }

  .case-study-section__image--finalscreen {
    max-width: 30%;
    width: 80%;
    margin: 0 0 1em 1em;
  }

  .case-study-section__content {
    &--row {
      flex-direction: row;
      margin-bottom: 4em;

      &-reverse {
        flex-direction: row-reverse;
      }
    }
  }

  .case-study-section__content--two-column {
    display: grid;
    grid-template-columns: [inner-start] 1fr [center] 1fr [inner-end];
    grid-template-rows: [inner-top] auto [center] auto [inner-bottom];
  }

  .case-study-section__content--two-column-hero {
    display: grid;
    grid-template-columns: [inner-start] 4fr [center] 11fr [inner-end];
    grid-template-rows: [inner-top] auto [center] auto [inner-bottom];
  }
  .case-study-section__role-duration,
  .case-study-section__business {
    grid-column: inner-start / center;
  }
  .case-study-section__role-duration {
    grid-row: inner-top / center;
  }
  .case-study-section__business {
    grid-row: center / inner-bottom;
  }
  .case-study-section__company {
    grid-column: center / inner-end;
    grid-row: inner-top / inner-bottom;
  }

  .case-study-section__duration,
  .case-study-section__description {
    max-width: 90%;
  }

  .case-study-section__description--hero {
    font-size: 1.6em;
  }

  .case-study-section--header {
    padding: 10em 0;
    .case-study-section__content {
      .case-study-section__info {
        grid-column: inner-start / center;
        align-self: center;
      }
      .case-study-section__image {
        grid-column: center / inner-end;
      }
    }
  }

  .case-study-section__content--row-open {
    display: flex;
    flex-direction: column;
    justify-content: center;

    & img {
      max-width: 70%;
      margin-bottom: 4em;
    }
  }

  .case-study-section__image--validation {
    margin-top: 2em;
  }
}

@media (min-width: 950px) {
  .case-study-section__description--hero span {
    display: block;
  }
}

@media (min-width: 1200px) {
  .case-study-section__image--row-reverse {
    margin-right: 5%;
  }

  .case-study-section__content--narrow > div:first-child {
    margin-right: 5%;
  }

  .case-study-section__image--row-reverse {
    max-width: 60%;
    margin: 0 5% 0 10%;
  }

  .case-study-section__image--final-results {
    max-width: 60%;
    margin-left: 5%;
  }
  .case-study-section__image--final-results-streamloan {
    max-width: 40%;
    height: 100%;
    margin-left: 10%;
  }

  .case-study-section__content {
    &--row {
      margin-bottom: 8em;
    }
  }

  .case-study-section__content--narrow-streamloan {
    margin-left: 10%;

    & h3,
    p {
      width: 80%;
    }
  }
  .case-study-section__content--narrow-streamloan-reverse {
    // margin-left: 20%;
    & h3,
    p {
      width: 70%;
    }
  }

  // .case-study-section__image--small {
  //   max-width: 70%;
  //   margin-left: 15%;
  // }
}
