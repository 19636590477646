$backgroundDefault: #FAF9EC;
$backgroundCasestudy: #FAF9EC;
$backgroundAccent: #D8B9FF;
$fontSecondary:#D8B9FF;
$bodyFont: "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$headerFont: "gilroy-extrabold";
$subheaderFont: "gilroy-light";
$bodyColor: #1b1b1b;
$bodyLight: #333333;
$ctaColor: #1b1b1b;
$subheaderColor: #7b7479;
$accentColor: #aa4ef5;
$lanternLight: #ededff;
$lanternDark: #d5a1ff;
$lanternResults: #f8f5ff;
$openDark: #a1d2ff;
$openLight: #e8f4ff;
$streamloanLight: #f4fdf8;
$streamloanDark: #c2ffd9;
$streamloanAccent: #a0e5ba;
$amazonLight: #fbe7d5;
$amazonDark: #ecf299;
$imageShadow: #e1e0e0;
$footerBackground: #1b1b1b;
